<template>
  <div>
    <Menu></Menu>
    <div>
      <div id="gpt">
        <h2 style="color: #555;margin-bottom: 30px;">俄语产品描述生成(服务暂停)</h2>
        <div style="display: flex;">
          <div style="width: 40%;margin-left:5%;margin-right: 5px;">
            <div style="display: flex;">
              <el-input v-model="prompt" placeholder="请输入产品名称（如：显示屏）" type="textarea"
                        :autosize="{ minRows: 2, maxRows: 100 }" style="padding: 0 2px 2px 0;"></el-input>
              <el-input v-model="pp" placeholder="请输入产品品牌（如：XDKY）" type="textarea"
                        :autosize="{ minRows: 2, maxRows: 100 }" style="padding-: 0 0 2px 2px;"></el-input>
            </div>
            <el-input v-model="ask" placeholder="请输入产品标签（如：24寸）" type="textarea"
                      :autosize="{ minRows: 5, maxRows: 100 }" style="margin-top: 5px;"></el-input>
          </div>
          <el-input type="textarea" v-model="anser" style="width: 50%;margin-right:5%;margin-left: 10px;"
                    :autosize="{ minRows: 8, maxRows: 100 }"
                    placeholder="产品描述，生成需要一段时间，请耐心等待"></el-input>
        </div>
        <el-button @click="gpt()" @submit.native.prevent :loading="loading" style="margin: 30px 0 20px 0;">
          生成
        </el-button>
      </div>
      <Footer style="margin-top: 100px;"></Footer>
    </div>
  </div>
</template>
<script>
import Menu from "../components/Menu.vue"
import Footer from "../components/footer.vue"

export default {
  data() {
    return {
      pp: "",
      ask: "",
      prompt: "",
      anser: "",
      loading: false
    }
  },
  methods: {
    // gpt() {
    //     console.log("使用" + this.ask + "这些标签，生成品牌为" + this.pp + "的" + this.prompt + "的俄语的产品描述，要求语言为俄语、字数500左右、以6条显示")
    //     this.loading = true // 开启 loading 状态
    //     this.$axios({
    //         method: 'post',
    //         url: 'https://api.openai.com/v1/completions',
    //         data: {
    //             model: 'text-davinci-003',
    //             prompt: "使用‘" + this.ask + "’标签，生成品牌为‘" + this.pp + "’的‘" + this.prompt + "’俄语的产品描述，要求语言为俄语、字数500左右、按条显示、语言为俄语",
    //             temperature: 0.5,
    //             max_tokens: 2000,
    //             top_p: 1,
    //             frequency_penalty: 0.0,
    //             presence_penalty: 0.6
    //         },
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer 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",
    //         }
    //     })
    //         .then((res) => {
    //             this.anser = res.data.choices[0].text.trim();
    //             this.loading = false; // 关闭 loading 状态
    //         })
    //         .catch((err) => {
    //             console.log(err.response.data);
    //             this.loading = false; // 关闭 loading 状态
    //             return "error";
    //         });
    // }
  },
  components: {
    Menu,
    Footer
  }
}
</script>

<style scoped>
#gpt {
  text-align: center;
  margin: 50px 20%;
  padding: 20px 0;
  background-color: rgba(250, 235, 215, 0.507);
  border-radius: 5px;
}

.el-button {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  color: #7f7f7f;
  border: none;
  border-radius: 5px;
  padding: 12px 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.el-button:hover {
  background-color: #7f7f7f;
  color: white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-text {
  color: #fff;
  font-size: 24px;
  margin-left: 10px;
}
</style>

<template>
  <div class="cooperative">
    <h1>物流官网</h1>
    <div class="plana">
      <div v-for="(item, index) in data" :key="index">
        <a :href="item.cooperativeUrl" target="_blank">
          <img :src="item.cooperativeImg" class="img3">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cooperativeType: '物流官网',
      data: []
    }
  },
  methods: {
    get() {
      this.$axios.get("/cooperative/getbytype", {params: {cooperativeType: this.cooperativeType}})
        .then((res) => {
            this.data = res.data.data
            this.data.forEach(element => {
              element.cooperativeImg = require('../../assets/img/cooperative/' + element.cooperativeImg)
            });
          }
        )
    }
  },
  created() {
    this.get()
  }
}
</script>

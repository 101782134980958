<template>
  <div id="vision">
    <div class="vs">
      <el-image id="tp1" src="../../assets/abs4.jpg"></el-image>
    </div>
    <div class="vs" style="margin-left: 2%;">
      <h2 style="margin-bottom: 2px;">我们的使命</h2>
      <span>让企业易跨境通全球</span>
      <p>
        ESG凭借对全球跨境电商市场的深入了解，以真诚的态度，
        为企业提供全球优质的电商渠道，提出领先的解决方案，助力企业快速打开海外市场，
        实现跨境多平台发展，从而提升销售额、货通全球 。
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
#vision {
  display: flex;
  text-align: center;
}

#tp1 {
  width: 100%;
  height: 300px;
}

#vision .vs {
  width: 25%;
  margin: 5% 0 10% 20%;
  text-align: left;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';
import qs from 'qs';
import * as echarts from 'echarts'

Vue.use(ElementUI);
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

axios.defaults.baseURL = 'http://47.106.162.222:8082'
// axios.defaults.baseURL = 'http://localhost:8082'

new Vue({
  axios,
  router,
  store,
  echarts,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="scheme">
    <div class="planb">
      <img src="../../assets/2.1.1.jpg">
      <img src="../../assets/2.1.2.jpg">
      <img src="../../assets/2.1.3.jpg">
      <img src="../../assets/2.2.1.jpg">
      <img src="../../assets/2.2.2.jpg">
      <img src="../../assets/2.2.3.jpg">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
#scheme {
  margin: 3%;
}

.planb {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}

.planb img {
  width: 30%;
  margin: 15px auto;
}

::-webkit-scrollbar {
  display: none;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


@media (max-width: 768px) {

  #scheme {
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .planb {
    margin: 10px 0;
    padding: 0;
    display: flex;
    text-align: center;
    flex-wrap: nowrap;
  }

  .planb img {
    width: 342px;
    margin: 0 10px;
    height: auto;
    box-shadow: 0px 0px 3px 3px rgba(202, 202, 202, 0.219);
  }

}
</style>

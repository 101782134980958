<template>
  <div>
    <Menu></Menu>
    <div class="user-profile">
      <div class="user-image">
        <el-image :src="userImg" id="center_userhead"
                  style="border-radius: 50%; box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); height: 180px; width: 180px;">
        </el-image>
        <h1 style="margin:20px; font-size: 48px; font-weight: bold;">
          {{ userName }}
        </h1>
      </div>
      <div class="user-info">
        <div style="display: flex;">
          <el-row>
            ID:{{ userId }}
          </el-row>
          <el-row style="margin-left: 20px;">
            手机号:{{ userPhone }}
          </el-row>
        </div>
        <div style="display: flex;">
          <el-row>
            性别:{{ userSex }}
          </el-row>
          <el-row style="margin-left: 20px;">
            微信号:{{ userWechat }}
          </el-row>
        </div>
        <router-link to="/center/updateuser">
          <el-button>修改个人信息</el-button>
        </router-link>
      </div>
    </div>
    <div id="myarticle">
      <h1 style="margin-bottom: 20px;">我的内容</h1>
      <el-main class="main" style="margin: 0;">
        <div class="articles">
          <div class="article" v-for="(article, index) in articles" :key="index"
               @click="ToArticleContent(article)">
            <div class="article-image">
              <img :src="article.articleImg" alt=""/>
            </div>
            <div class="article-info">
              <h2>{{ article.articleTitle }}</h2>
              <p>{{ article.articleIntroduction }}</p>
            </div>
          </div>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";


export default {
  data() {
    return {
      articleIds: [],
      articles: [],
      userId: "",
      userName: "",
      userSex: "",
      userWechat: "",
      userPhone: "",
      userImg: "",
    };
  },
  methods: {
    getUserData() {
      this.$axios
        .get("/user/getbyid", {
          params: {userId: this.$store.state.userId},
        })
        .then((response) => {
          const userData = response.data.data;
          this.userId = userData.userId;
          this.userName = userData.userName;
          this.userSex = userData.userSex;
          this.userWechat = userData.userWechat;
          this.userPhone = userData.userPhone;
          this.userImg = userData.userImg;
          this.getUserArticleIds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserArticleIds() {
      this.$axios.get("/userarticle/getarticleids", {params: {userId: this.$store.state.userId}})
        .then((response) => {
          this.articleIds = response.data.data;
          this.getUserArticle(this.articleIds);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getUserArticle(articleIds) {
      const requests = articleIds.map(id => this.$axios.get("/article/getbyid", {params: {articleId: id}}));
      try {
        const responses = await Promise.all(requests);
        this.articles = responses.map(response => response.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    ToArticleContent(article) {
      this.$store.state.articleId = article.articleId
      this.$router.push("/comment")
    },
  },
  created() {
    this.getUserData();
  },
  mounted() {
    this.getUserArticle(this.articleIds)
  },
  components: {
    Menu,
  },
};
</script>

<style scoped>
#myarticle {
  background-color: #f8f8f8;
  border-radius: 5px;
  margin: 50px 15%;
  padding-top: 50px;
  text-align: center;
}

h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.main {
  flex: 1;
  gap: 20px;
  background-color: #fff;
}


.articles {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.article {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.article:hover {
  box-shadow: 3px 10px 12px 2px rgba(0, 0, 0, 0.1);
  transform: translate(-2px, -2px);
}


.article-image img {
  width: 200px;
  height: 120px;
  object-fit: cover;
}

.article-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-profile {
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding: 30px;
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.user-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.user-image h1 {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 2;
  align-items: center;
}

@media screen and (max-width: 768px) {

  #myarticle {
    width: auto;
    margin: 20px 0;
    padding: 20px;
  }

  .main {
    text-align: center;
  }

  .article {
    display: block;
  }

  .user-profile {
    flex-direction: column;
  }

  .user-image {
    margin-bottom: 20px;
  }

}
</style>

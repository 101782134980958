<template>
  <div id="corporation" style="margin: 3% 12% 0 12%">
    <h2 style="margin-left: 2%;">公司介绍</h2>
    <div>
      <img src="../../assets/关于我们 主图.jpg" id="aboutImg">
    </div>
    <div class="aboutText">
      <p class="abouttxt" style="margin-top: 5px;">
        白桦林跨境电商是NIUKU INT的俄语区电商市场项目，于2021年年初立项，2021年8月开始组建俄罗斯团队，项目目前包含两家公司。
      </p>
      <p class="abouttxt">白桦林(广州)跨境电商有限公司和 OOO bepe3oBas powa，在中国广州，俄罗斯莫斯科、圣彼得堡均有办公室和运营人员。
      </p>
      <p class="abouttxt">
        白桦林跨境电商专注开发俄语区市场，为中国卖家进入俄罗斯电商市场提供咨询、培训、推广、运营、品牌、知识产权相关服务。</p>
      <p class="abouttxt" style="margin-bottom: 20px;">
        与俄罗斯的多家合作方一道，从市场端出发，解决跨境贸易中常见的本地化问题。结合中国优势的供应链资源，实现中国优质品牌在俄罗斯市场获得消费者的认可。</p>

    </div>
    <div>
      <!-- <img :src="abImg" style="width: 100%;height: auto;margin-top: 50px;"> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      abImg: require('../../assets/about/2.jpg')
    }
  },
  methods: {
    get() {
      // this.$nextTick(() => {
      //   const screenWidth = window.innerWidth
      //   if (screenWidth < 768) {
      //     this.abImg = 'https://i.tosoiot.com/20230509/t/8f00a73bf95a98df.jpg'
      //   }
      // })
    }
  },
  created() {
    this.get()
  }
}
</script>

<style scoped>
#aboutImg {
  border-radius: 5px;
  float: left;
  width: 50%;
  height: auto;
  margin-right: 20px;
}

.abouttxt {
  height: auto;
  font-size: 1.2vw;
  line-height: 1.6vw;
}

p {
  margin: 1vw 0;
}

#par {
  text-align: center;
  display: flex;
  justify-content: center;
}

.aboutText {
  width: 100%;
  padding-top: 5%;
}

@media screen and (max-width: 860px) {

  .aboutText {
    width: 100%;
  }

  #aboutImg {
    width: 100%;
    height: auto;
  }

  .abouttxt {
    height: auto;
    font-size: 12px;
  }

  p {
    margin: 12px 0;
  }
}
</style>

<template>
  <div id="Navigation" style="background-color: whitesmoke;background-size: 100%;">
    <Menu></Menu>
    <!-- <img :src="imgUrl" class="zszximg"> -->
    <Erp></Erp>
    <Logistics></Logistics>
    <Query></Query>
    <Knowledge></Knowledge>
    <Payable></Payable>
    <Img></Img>
    <Good></Good>
    <Fgl></Fgl>
    <Shopping></Shopping>
    <Trademark></Trademark>
    <Data></Data>
    <Forum></Forum>
    <Util></Util>
    <Footer></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue"
import Erp from "../components/Cooperative/Erp.vue"
import Logistics from "../components/Cooperative/Logistics.vue"
import Knowledge from "../components/Cooperative/Knowledge.vue"
import Payable from "../components/Cooperative/Payable.vue"
import Img from "../components/Cooperative/Img.vue"
import Forum from "../components/Cooperative/Forum.vue"
import Util from "../components/Cooperative/Util.vue"
import Data from "../components/Cooperative/Data.vue"
import Fgl from "../components/Cooperative/fgl.vue"
import Good from "../components/Cooperative/Good.vue"
import Shopping from "../components/Cooperative/Shopping.vue"
import Trademark from "../components/Cooperative/Trademark.vue"
import Query from "../components/Cooperative/Query.vue"
import Footer from "../components/footer.vue"

export default {
  data() {
    return {
      imgUrl: "https://i.tosoiot.com/20230506/t/7a4a72f9a2e728c3.jpg"
    }
  },
  components: {
    Query,
    Data,
    Fgl,
    Good,
    Shopping,
    Trademark,
    Payable,
    Logistics,
    Knowledge,
    Menu,
    Erp,
    Util,
    Img,
    Forum,
    Footer
  },
  methods: {
    get() {
      this.$nextTick(() => {
        const screenWidth = window.innerWidth
        if (screenWidth < 768) {
          this.imgUrl = "https://i.tosoiot.com/20230506/t/c9e532e0934401f6.jpg"
        }
      })
    }
  },
  created() {
    this.get()
  }
}
</script>

<style>
.zszximg {
  height: 380px;
  width: 100%;
}

.cooperative {
  margin: 5% 10%;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
}

h1 {
  margin: 0;
  padding: 5px 0 0px 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.plana {
  flex-wrap: wrap;
  display: flex;
  text-align: left;
}

.plana a {
  margin: 10px;
  text-decoration: none;
}

.plana .img3 {
  margin: 0;
  margin-top: 10px;
  width: 220px;
  height: 85px;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
}

.plana .img3:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 860px) {

  .cooperative {
    margin: 20px 0;
  }

  .plana .img3 {
    margin: 0;
    margin-top: 10px;
    width: 145px;
    height: 60px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }
}
</style>

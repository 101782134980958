<template>
  <div class="carousel-wrapper" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <el-carousel ref="carousel" :interval="3000" :style="{ height: screenheight + 'vw' }" :arrow=isMobile
                 :indicator-position="isMobile ? 'none' : ''">
      <el-carousel-item v-for="item in imgs" :key="item.id" style="width: 100%;">
        <img :src="item.src" class="carousel-img"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          id: 1,
          src: require('../../assets/首页1.jpg'),
        },
        {
          id: 2,
          src: require('../../assets/首页2.jpg'),
        },
        {
          id: 3,
          src: require('../../assets/首页3.jpg'),
        },
      ],
      screenheight: 35,
      isMobile: false,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    };
  },
  created() {
    // this.calculateHeight();
    window.addEventListener('resize', this.calculateHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateHeight);
  },
  methods: {
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      this.endX = e.touches[0].clientX;
      this.endY = e.touches[0].clientY;
    },
    handleTouchEnd() {
      const distanceX = this.endX - this.startX;
      const distanceY = this.endY - this.startY;
      if (Math.abs(distanceX) > Math.abs(distanceY)) {
        if (distanceX > 0) {
          this.$refs.carousel.prev();
        } else {
          this.$refs.carousel.next();
        }
      }
    },
    // calculateHeight() {
    //     const windowWidth = window.innerWidth;
    //     this.isMobile = windowWidth < 768;
    //     if (this.isMobile) {
    //         this.imgs = [
    //             {
    //                 id: 1,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //             {
    //                 id: 2,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //             {
    //                 id: 3,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //             {
    //                 id: 4,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //         ];
    //     } else {
    //         this.imgs = [
    //             {
    //                 id: 1,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //             {
    //                 id: 2,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //             {
    //                 id: 3,
    //                 src: require('../../assets/1.jpg'),
    //             },
    //         ];
    //     }
    // },
  },
};
</script>

<style>
.el-carousel__container {
  height: 100% !important;
  margin-bottom: 0;
  overflow: hidden;
}
</style>

<style scoped>
.carousel-wrapper {
  width: 100%;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

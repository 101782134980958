<template>
  <div>
    <Menu></Menu>
    <div style="width: 100%;height: 100%;background-size: 100%;position: fixed;">
      <div id="loginFrom">
        <el-form>
          <h1 style="font-size: 42px;margin: 4% auto;" v-show="isShow">登录</h1>
          <h1 style="font-size: 42px;margin: 4% auto;" v-show="!isShow">注册</h1>
          <el-form-item v-show="isShow">
            <p class="text">账号</p>
            <!-- v-model双向绑定；placeholder提示 -->
            <el-input style="height: 42px;" v-model.number="userId" placeholder="请输入账号" type="text"/>
          </el-form-item>
          <el-form-item v-show="!isShow">
            <p class="text">用户名</p>
            <!-- v-model双向绑定；placeholder提示 -->
            <el-input style="height: 42px;" v-model="userName" placeholder="请输入用户名" type="text"/>
          </el-form-item>
          <el-form-item>
            <p class="text">密码</p>
            <!-- show-password小眼睛 -->
            <el-input style="height: 40px;margin-bottom: 4%;" v-model="password" type="password"
                      placeholder="请输入密码" show-password/>
          </el-form-item>
          <el-form-item v-show="!isShow">
            <p class="text">确认密码</p>
            <!-- show-password小眼睛 -->
            <el-input style="height: 40px;margin-bottom: 4%;" v-model="password2" type="password"
                      placeholder="请再次输入密码" show-password/>
          </el-form-item>
          <!-- @click="Login()"点击触发Login()方法 -->
          <el-button @click="Login()" style="margin-right: 3%;" v-show="isShow">登录</el-button>
          <el-button @click="toRegister()" v-show="isShow">注册</el-button>
          <el-button @click="toRegister()" v-show="!isShow">登录</el-button>
          <el-button @click="Register()" v-show="!isShow">注册</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue"

export default {
  data() {
    return {
      isShow: true,
      // 与v-model绑定的数据
      userId: '',
      userName: '',
      password: '',
      password2: ''
    }
  },
  methods: {
    Login() {
      if (this.userId == "") {
        this.$alert("账号不能为空", {
          confirmButtonText: '确定',
        });
      } else if (this.password == "") {
        this.$alert("密码不能为空", {
          confirmButtonText: '确定',
        });
      } else {
        this.$axios.post(
          "/user/login",
          this.$qs.stringify({
            userId: this.userId,
            userPassword: this.password
          })
        )
          .then((res) => {
              if (res.data.msg == '管理员' || res.data.msg == '超级管理员') {
                this.$store.state.userRole = res.data.msg
                localStorage.userRole = res.data.msg
                this.$store.state.userId = this.userId
                localStorage.userId = this.userId
                this.$router.push("/back/article")
              } else if (res.data.msg == '用户') {
                localStorage.userRole = res.data.msg
                this.$store.state.userRole = res.data.msg
                this.$store.state.userId = this.userId
                localStorage.userId = this.userId
                this.$router.push("/")
              } else {
                this.$alert(res.data.msg, {
                  confirmButtonText: '确定',
                });
              }
            }
          )
      }
    },
    toRegister() {
      this.isShow = !this.isShow
    },
    Register() {
      if (this.userName == "") {
        this.$alert("用户名不能为空", {
          confirmButtonText: '确定',
        });
      } else if (this, this.password.length < 6) {
        this.$alert("密码不能小于6位", {
          confirmButtonText: '确定',
        });
      } else if (this.password == this.password2) {
        this.$axios.post(
          "/user/register",
          this.$qs.stringify({
            userName: this.userName,
            userPassword: this.password
          })
        )
          .then((res) => {
              this.$alert(res.data.msg, {
                confirmButtonText: '确定',
              });
            }
          )
      } else
        this.$alert("两次密码不同", {
          confirmButtonText: '确定',
        });
    }
  },
  components: {
    Menu
  }
}
</script>

<style>
#loginFrom {
  margin: 3% auto;
  padding: 3%;
  min-width: 300px;
  max-width: 25%;
  text-align: center;
  /* 背景 */
  background-color: rgba(255, 255, 255, 0.856);
  /* background-image: url("../assets/loginbg.jpg"); */
  /* 圆角 */
  border-radius: 5%;
  /* 字体颜色 */
  color: rgb(0, 0, 0);
  font-weight: 800;
}

/* 按钮 */
#loginButton {
  margin-top: 10px;
  min-height: 40px;
  min-width: 16%;
}

/* 文本 */
.text {
  font-size: 20px;
  margin: 0;
  text-align: left;
}
</style>

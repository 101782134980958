import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ArticleStudyView from '../views/ArticleStudyView.vue'
import NavigationView from '../views/NavigationView.vue'
import AboutView from '../views/AboutCView.vue'
import GptView from '../views/chatgpt.vue'
import Translate from '../views/Translate.vue'
import BackView from '../views/BackView.vue'
import CenterView from '../views/PersonalCenterView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: HomeView
  },
  {
    path: '/gpt',
    name: 'gpt',
    component: GptView
  },
  {
    path: '/fy',
    name: '翻译',
    component: Translate
  },
  {
    path: '/login',
    name: '登录',
    component: LoginView
  },
  {
    path: '/center',
    name: '个人中心',
    component: CenterView
  },
  {
    path: '/forum',
    name: '论坛',
    component: () => import("../components/Vip/Forum.vue"),
  },
  {
    path: '/forumadd',
    name: '文章发布',
    component: () => import("../components/Vip/ForumAdd.vue"),
  },
  {
    path: '/comment',
    name: '论坛评论',
    component: () => import("../components/Vip/Comment.vue")

  },
  {
    path: '/profit',
    name: '利润计算',
    component: () => import("../components/Vip/Profit.vue")
  },
  {
    path: '/sellerstats',
    name: '商家统计',
    component: () => import("../components/Vip/Sellerstats.vue")
  },
  {
    path: '/video',
    name: 'VIP视频',
    component: () => import("../components/Vip/Video.vue")
  },
  {
    path: '/viparticle',
    name: 'VIP文章',
    component: () => import("../components/Vip/Article/Article.vue"),
    children: [
      {
        path: '/viparticle/',
        component: () => import("../components/Vip/Article/Index.vue")
      },
      {
        path: '/viparticle/jcyh',
        component: () => import("../components/Vip/Article/Advertisement/jcyh.vue")
      },
      {
        path: '/viparticle/ssym',
        component: () => import("../components/Vip/Article/Advertisement/ssym.vue")
      },
      {
        path: '/viparticle/spnryq',
        component: () => import("../components/Vip/Article/Advertisement/spnryq.vue")
      },
      {
        path: '/viparticle/spdzsms',
        component: () => import("../components/Vip/Article/Advertisement/spdzsms.vue")
      },
      {
        path: '/viparticle/spccggyq',
        component: () => import("../components/Vip/Article/Advertisement/spccggyq.vue")
      },
      {
        path: '/viparticle/mbggkq',
        component: () => import("../components/Vip/Article/Advertisement/mbggkq.vue")
      },
      {
        path: '/viparticle/dyz',
        component: () => import("../components/Vip/Article/Advertisement/dyz.vue")
      },
      {
        path: '/viparticle/dez',
        component: () => import("../components/Vip/Article/Advertisement/dez.vue")
      },
      {
        path: '/viparticle/dsz',
        component: () => import("../components/Vip/Article/Advertisement/dsz.vue")
      },
      {
        path: '/viparticle/ggdyf',
        component: () => import("../components/Vip/Article/Advertisement/ggdyf.vue")
      },
      {
        path: '/viparticle/gghdxxtj',
        component: () => import("../components/Vip/Article/Advertisement/gghdxxtj.vue")
      },
      {
        path: '/viparticle/cjgzss',
        component: () => import("../components/Vip/Article/Advertisement/cjgzss.vue")
      },
      {
        path: '/viparticle/kggmd',
        component: () => import("../components/Vip/Article/Advertisement/kggmd.vue")
      },
      {
        path: '/viparticle/cjbz',
        component: () => import("../components/Vip/Article/Advertisement/cjbz.vue")
      },
      {
        path: '/viparticle/xyzydsj',
        component: () => import("../components/Vip/Article/Advertisement/xyzydsj.vue")
      },
      {
        path: '/viparticle/elsscgk',
        component: () => import("../components/Vip/Article/Selection/elsscgk.vue")
      },
      {
        path: '/viparticle/elskhhxjs',
        component: () => import("../components/Vip/Article/Selection/elskhhxjs.vue")
      },
      {
        path: '/viparticle/rhljelsmj',
        component: () => import("../components/Vip/Article/Selection/rhljelsmj.vue")
      }, {
        path: '/viparticle/ozonptjj',
        component: () => import("../components/Vip/Article/Selection/ozonptjj.vue")
      },
      {
        path: '/viparticle/yjgz',
        component: () => import("../components/Vip/Article/Selection/yjgz.vue")
      },
      {
        path: '/viparticle/jslm',
        component: () => import("../components/Vip/Article/Selection/jslm.vue")
      },
      {
        path: '/viparticle/ozonllll',
        component: () => import("../components/Vip/Article/Selection/ozonllll.vue")
      },
      {
        path: '/viparticle/ozonscsjfx',
        component: () => import("../components/Vip/Article/Selection/ozonscsjfx.vue")
      },
      {
        path: '/viparticle/elsmjdshxg',
        component: () => import("../components/Vip/Article/Selection/elsmjdshxg.vue")
      },
      {
        path: '/viparticle/hdrl',
        component: () => import("../components/Vip/Article/Selection/hdrl.vue")
      },
      {
        path: '/viparticle/2023dcrl',
        component: () => import("../components/Vip/Article/Selection/2023dcrl.vue")
      },
      {
        path: '/viparticle/dchdtjlm',
        component: () => import("../components/Vip/Article/Selection/dchdtjlm.vue")
      },
      {
        path: '/viparticle/dcqjhdjj',
        component: () => import("../components/Vip/Article/Selection/dcqjhdjj.vue")
      },
      {
        path: '/viparticle/ps·jslm',
        component: () => import("../components/Vip/Article/Selection/ps·jslm.vue")
      },
      {
        path: '/viparticle/rhcky',
        component: () => import("../components/Vip/Article/Selection/rhcky.vue")
      },
      {
        path: '/viparticle/qtptd',
        component: () => import("../components/Vip/Article/Selection/qtptd.vue")
      },
      {
        path: '/viparticle/sswzy',
        component: () => import("../components/Vip/Article/Selection/sswzy.vue")
      },
      {
        path: '/viparticle/tgyou',
        component: () => import("../components/Vip/Article/Selection/tgyou.vue")
      },
      {
        path: '/viparticle/yzzbs',
        component: () => import("../components/Vip/Article/Selection/yzzbs.vue")
      },
      {
        path: '/viparticle/cyhxp',
        component: () => import("../components/Vip/Article/Selection/cyhxp.vue")
      },
      {
        path: '/viparticle/lmswxp',
        component: () => import("../components/Vip/Article/Selection/lmswxp.vue")
      },
      {
        path: '/viparticle/cpmd',
        component: () => import("../components/Vip/Article/Selection/cpmd.vue")
      },
      {
        path: '/viparticle/gzjpd',
        component: () => import("../components/Vip/Article/Selection/gzjpd.vue")
      },
      {
        path: '/viparticle/zshyd',
        component: () => import("../components/Vip/Article/Selection/zshyd.vue")
      },
      {
        path: '/viparticle/scssl',
        component: () => import("../components/Vip/Article/Selection/scssl.vue")
      },
      {
        path: '/viparticle/elsdf',
        component: () => import("../components/Vip/Article/Selection/elsdf.vue")
      },
      {
        path: '/viparticle/xfbzt',
        component: () => import("../components/Vip/Article/Selection/xfbzt.vue")
      },
      {
        path: '/viparticle/cjxpcw',
        component: () => import("../components/Vip/Article/Selection/cjxpcw.vue")
      },
      {
        path: '/viparticle/zngj',
        component: () => import("../components/Vip/Article/Selection/zngj.vue")
      },
      {
        path: '/viparticle/ozonxpyz',
        component: () => import("../components/Vip/Article/Selection/ozonxpyz.vue")
      },
      {
        path: '/viparticle/ppsq',
        component: () => import("../components/Vip/Article/Selection/ppsq.vue")
      },
      {
        path: '/viparticle/ozonfx',
        component: () => import("../components/Vip/Article/Analyse/ozonfx.vue")
      },
      {
        path: '/viparticle/xsld',
        component: () => import("../components/Vip/Article/Analyse/xsld.vue")
      },
      {
        path: '/viparticle/yyedt',
        component: () => import("../components/Vip/Article/Analyse/yyedt.vue")
      },
      {
        path: '/viparticle/spss',
        component: () => import("../components/Vip/Article/Analyse/spss.vue")
      },
      {
        path: '/viparticle/spxz',
        component: () => import("../components/Vip/Article/Analyse/spxz.vue")
      },
      {
        path: '/viparticle/spdd',
        component: () => import("../components/Vip/Article/Analyse/spdd.vue")
      },
      {
        path: '/viparticle/fxtb',
        component: () => import("../components/Vip/Article/Analyse/fxtb.vue")
      },
      {
        path: '/viparticle/zbsz',
        component: () => import("../components/Vip/Article/Analyse/zbsz.vue")
      },
      {
        path: '/viparticle/fxbg',
        component: () => import("../components/Vip/Article/Analyse/fxbg.vue")
      },
      {
        path: '/viparticle/wlth',
        component: () => import("../components/Vip/Article/Analyse/wlth.vue")
      },
      {
        path: '/viparticle/spdyjhbc',
        component: () => import("../components/Vip/Article/Analyse/spdyjhbc.vue")
      },
      {
        path: '/viparticle/spdyjfhbcj',
        component: () => import("../components/Vip/Article/Analyse/spdyjfhbcj.vue")
      },
      {
        path: '/viparticle/zlgl',
        component: () => import("../components/Vip/Article/Analyse/zlgl.vue")
      },
      {
        path: '/viparticle/qxl',
        component: () => import("../components/Vip/Article/Analyse/qxl.vue")
      },
      {
        path: '/viparticle/yqfh',
        component: () => import("../components/Vip/Article/Analyse/yqfh.vue")
      },
      {
        path: '/viparticle/asjhd',
        component: () => import("../components/Vip/Article/Analyse/asjhd.vue")
      },
      {
        path: '/viparticle/yqjh',
        component: () => import("../components/Vip/Article/Analyse/yqjh.vue")
      },
      {
        path: '/viparticle/rhsyss',
        component: () => import("../components/Vip/Article/Analyse/rhsyss.vue")
      },
      {
        path: '/viparticle/sszdsp',
        component: () => import("../components/Vip/Article/Analyse/sszdsp.vue")
      },
      {
        path: '/viparticle/smsqs',
        component: () => import("../components/Vip/Article/Analyse/smsqs.vue")
      },
      {
        path: '/viparticle/qsgjxs',
        component: () => import("../components/Vip/Article/Analyse/qsgjxs.vue")
      },
      {
        path: '/viparticle/smsjz',
        component: () => import("../components/Vip/Article/Analyse/smsjz.vue")
      },
      {
        path: '/viparticle/rhsyjz',
        component: () => import("../components/Vip/Article/Analyse/rhsyjz.vue")
      },
      {
        path: '/viparticle/mjxx',
        component: () => import("../components/Vip/Article/Analyse/mjxx.vue")
      },
      {
        path: '/viparticle/rmsp',
        component: () => import("../components/Vip/Article/Analyse/rhsyjz.vue")
      },
      {
        path: '/viparticle/fxsscx',
        component: () => import("../components/Vip/Article/Analyse/fxsscx.vue")
      },
      {
        path: '/viparticle/kyaznx',
        component: () => import("../components/Vip/Article/Analyse/kyaznx.vue")
      },
      {
        path: '/viparticle/qssp',
        component: () => import("../components/Vip/Article/Analyse/qssp.vue")
      },
      {
        path: '/viparticle/ptrhjs',
        component: () => import("../components/Vip/Article/Analyse/ptrhjs.vue")
      },
      {
        path: '/viparticle/ycs',
        component: () => import("../components/Vip/Article/Analyse/ycs.vue")
      },
    ]
  },
  {
    path: '/center/updateuser',
    component: () => import("../components/Center/updateuser.vue")
  },
  {
    path: '/stud',
    name: '卖家学习中心',
    component: ArticleStudyView,
    children: [
      {
        path: '/study',
        component: () => import("../components/Study/ArticleStudy")
      }
    ]
  },
  {
    path: '/navigation',
    name: '跨界导航',
    component: NavigationView
  },
  {
    path: '/aboutc',
    name: '关于我们',
    component: AboutView
  },
  {
    path: '/back',
    name: '白桦林后台',
    component: BackView,
    children: [
      {
        path: '/back/article',
        component: () => import("../components/Back/article/getarticle.vue")
      },
      {
        path: '/back/addarticle',
        component: () => import("../components/Back/article/addarticle.vue")
      },
      {
        path: '/back/updatearticle',
        component: () => import("../components/Back/article/updatearticle.vue")
      },
      {
        path: '/back/delarticle',
        component: () => import("../components/Back/article/delarticle.vue")
      },
      {
        path: '/back/comment',
        component: () => import("../components/Back/comment/getcomment.vue")
      },
      {
        path: '/back/addcomment',
        component: () => import("../components/Back/comment/addcomment.vue")
      },
      {
        path: '/back/updatecomment',
        component: () => import("../components/Back/comment/updatecomment.vue")
      },
      {
        path: '/back/delcomment',
        component: () => import("../components/Back/comment/delcomment.vue")
      },
      {
        path: '/back/user',
        component: () => import("../components/Back/user/getuser.vue")
      },
      {
        path: '/back/adduser',
        component: () => import("../components/Back/user/adduser.vue")
      },
      {
        path: '/back/updateuser',
        component: () => import("../components/Back/user/updateuser.vue")
      },
      {
        path: '/back/userarticle',
        component: () => import("../components/Back/user/userarticle.vue")
      },
      {
        path: '/back/updateuserarticle',
        component: () => import("../components/Back/user/userarticleupdate.vue")
      },
      {
        path: '/back/adduserarticle',
        component: () => import("../components/Back/user/userarticleadd.vue")
      },
      {
        path: '/back/cooperative',
        component: () => import("../components/Back/cooperative/getcooperative.vue")
      }, {
        path: '/back/addcooperative',
        component: () => import("../components/Back/cooperative/addcooperative.vue")
      },
      {
        path: '/back/updatecooperative',
        component: () => import("../components/Back/cooperative/updatecooperative.vue")
      },
      {
        path: '/back/delcooperative',
        component: () => import("../components/Back/cooperative/delcooperative.vue")
      }
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: () => import("../views/Test.vue")
  }
]

const router = new VueRouter({
  mode: 'history',  //去掉url中的#
  routes
})

// 注册一个全局前置守卫
router.beforeEach((to, from, next) => {
  // let role = this.$store.state.userRole;
  if (to.path === "/back" || to.path === "/back/*") {    //判断当前路由是否需要进行权限控制
    if (localStorage.userRole === "管理员") {    //权限控制的具体规则
      next()
    } else {
      alert("权限不足")
      next("/")
    }
  } else next();
})

export default router

<template>
  <router-view/>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {}
  }
}
</script>

<style>

</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    userRole: "游客",
    userId: '',
    userArticle: '',
    userArticleId: '',
    userVideoId: '',
    article: [],
    articleId: 0,
    articleContent: '',
    cooperative: '',
    comment: ''
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})

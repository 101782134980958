<template>
  <div id="myfooter">
    <div class="footer_block" id="footer_left">
      <div class="footer_text" id="lxwm" style="width: 45%;min-width: 300px;">
        <h2>联系我们</h2>
        <p>微信：OOZZOONN</p>
        <p>邮箱：jun@elsbhl.con</p>
        <p>地址: 广东省深圳市南山区 高新南七道高新技术产业园R3一B2楼223</p>
        <p>圣彼得堡办公室：Санкт-Петербург Чапаева 15 лит А БЦ Сенатор, Санкт-Петербург, 197046</p>
        <p>莫斯科办公室：Москва, поселение Сосенское, Калужское шоссе, 22-й километр, 10, стр. 32</p>
      </div>
      <div class="footer_text" id="text2">
        <h2>卖家中心</h2>
        <router-link to="/study">
          <p>最新资讯</p>
        </router-link>
      </div>
      <div class="footer_text" id="ft2">
        <h2>关于我们</h2>
        <router-link to="/aboutc">
          <p>了解白桦林</p>
        </router-link>
        <router-link to="/login">
          <p>入驻ozon</p>
        </router-link>
        <p>
          <el-link href="https://seller.ozon.ru/ch/" target="_blank" style="margin: 0;padding: 0;"
                   :underline="false">
        <p style="margin: 0;padding: 0;">ozon官网</p>
        </el-link>
        </p>
        <p>
          <el-link href="https://docs.ozon.ru/global/zh/commissions/ozon-fees/commissions/" target="_blank"
                   style="margin: 0;padding: 0;" :underline="false">
        <p style="margin: 0;padding: 0;">ozon佣金</p>
        </el-link>
        </p>
      </div>
    </div>
    <div class="footer_block" id="footer_right">
      <div class="footer_right_d1">
        <h2>公众号</h2>
        <img src="../assets/footer4.jpg" class="footer_right_img">
      </div>
    </div>
    <div id="footerimg">
      <img v-if="imageUrl" :src="imageUrl" alt="image">
    </div>
    <div class="buttons">
      <button class="button" @click="showImage('https://i.tosoiot.com/20230506/t/ef9476ece4874238.png')">
        <img src="../assets/f1.jpg" style="height: 40px;width: 40px;border-radius: 2px;">
      </button>
      <button class="button" @click="showImage('https://i.tosoiot.com/20230506/t/5be20566a14197a8.jpg')">
        <img src="../assets/f2.jpg" style="height: 40px;width: 40px;border-radius: 2px;">
      </button>
      <button class="button" @click="showImage('https://i.tosoiot.com/20230506/t/62484d9f656eb573.png')">
        <img src="../assets/f3.jpg" style="height: 40px;width: 40px;border-radius: 2px;">
      </button>
      <button class="button" @click="showImage('https://i.tosoiot.com/20230506/t/bda28e968d83896e.png')">
        <img src="../assets/f4.jpg" style="height: 40px;width: 40px;border-radius: 2px;">
      </button>
      <button class="button" @click="showImage('https://i.tosoiot.com/20230506/t/e533cf0f521bad03.png')">
        <img src="../assets/f5.jpg" style="height: 40px;width: 40px;border-radius: 2px;">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: '',
    };
  },
  methods: {
    showImage(image) {
      if (this.imageUrl == image) {
        this.imageUrl = ''
      } else {
        this.imageUrl = image
      }
    },
  },
}
</script>

<style scoped>
#myfooter {
  background-color: #222c3c;
  margin: 0;
  padding: 0;
  display: flex;
  color: white;
  flex-wrap: wrap;
}

#myfooter p {
  color: white;
  margin: 10px 0;
  font-size: 18px;
}

.footer_text {
  width: 15%;
  min-width: 100px;
  margin: 0 1%;
  padding: 12px 3%;
}

#footer_left {
  color: white;
  padding: 12px 3%;
  display: flex;
  flex-wrap: wrap;
}

.footer_text h2 {
  color: white;
  margin: 20px auto;
}

#lxwm p {
  margin: 20px 0;
}

#footer_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer_right_d1 {
  margin: -8% 5% 0 5%;
  width: 60%;
}

.footer_right_img {
  width: 100%;
  height: auto;
  margin: 0;
}

#footerimg img {
  width: 200px;
  height: 200px;
}

.buttons {

  display: none;
  justify-content: center;
  align-items: center;
}

.button {
  margin: 5px;
  padding: 10px;
  background-color: #222c3c;
  border: none;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {

  #footerimg {
    margin-bottom: -200px;
    position: absolute;
  }

  .buttons {
    display: flex;
  }

  #lxwm h2 {
    margin-left: 30%;
  }

  #ft2,
  #text2,
  #footer_right {
    display: none;
  }

  .footer_right_d1 h2 {
    margin-left: 30%;
  }

  #myfooter {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #text2 {
    margin-left: 8%;
  }

}
</style>

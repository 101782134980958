<template>
  <div id="About">
    <Menu></Menu>
    <Corporation></Corporation>
    <div id="abimg1">
      <img :src="Img1" id="abimg11">
    </div>
    <div class="fzlc">
      <h1 style="margin: 5% 5% 2% 5%;text-align: center;font-size: 38px;">发展历程</h1>
      <img :src="Img2" style="margin-bottom: 5%;width: 80%;height: auto;">
    </div>
    <Footer></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Footer from "../components/footer.vue"
import Menu from "../components/Menu.vue"
import Corporation from "../components/About/Corporation.vue"
import Vision from "../components/About/Vision.vue"

export default {
  data() {
    return {
      Img1: require('../assets/about/1.jpg'),
      Img2: require('../assets/about/2.jpg')
    }
  },
  methods: {
    get() {
      // this.$nextTick(() => {
      //   const screenWidth = window.innerWidth
      //   if (screenWidth < 768) {
      //     this.Img1 = 'https://i.tosoiot.com/20230509/t/6d230aaafbc4d299.jpg'
      //     this.Img2 = 'https://i.tosoiot.com/20230509/t/3f138b0c78c6caaa.jpg'
      //   }
      // })
    }
  },
  components: {
    Menu,
    Corporation,
    Vision,
    Footer
  },
  created() {
    this.get()
  }
}
</script>

<style scoped>
.fzlc {
  height: auto;
  text-align: center;
}

#abimg11 {
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 860px) {
  .fzlc {
    height: auto;
    text-align: center;
    padding: 0 8%;
  }

  #abimg1 {
    width: 90%;
    margin: 0 auto;
  }

  #abimg11 {
    border-radius: 3px;
  }

}
</style>

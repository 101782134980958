<template>
  <div id="rights">
    <img class="RImg" src="../../assets/入驻福利1.jpg">
    <img class="RImg" src="../../assets/入驻福利2.jpg">
    <img class="RImg" src="../../assets/入驻福利3.jpg">
  </div>
</template>

<script>
export default {}
</script>

<style>
.RImg {
  width: 30%;
}

#rights {
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {

  .RImg {
    width: 300px;
  }

}
</style>

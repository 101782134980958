<template>
  <div id="Home">
    <Menu></Menu>
    <Rotation></Rotation>
    <div style="margin-top: -10px;">
      <el-image v-show="!isPhone" class="homeimg" :src="img1"></el-image>
      <el-image v-show="isPhone" class="homeimg" :src="img4"></el-image>
    </div>
    <Terrace></Terrace>
    <div>
      <el-image v-show="!isPhone" class="homeimg" :src="img2"></el-image>
      <el-image v-show="isPhone" class="homeimg" :src="img5"></el-image>
    </div>
    <Scheme></Scheme>
    <div>
      <el-image v-show="!isPhone" class="homeimg" :src="img3"></el-image>
      <el-image v-show="isPhone" class="homeimg" :src="img6"></el-image>
    </div>
    <Rights></Rights>
    <HZHB></HZHB>
    <Footer></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Rotation from "../components/Home/rotation.vue"
import Terrace from "../components/Home/terrace.vue"
import Scheme from "../components/Home/scheme.vue"
import Rights from "../components/Home/rights.vue"
import HZHB from "../components/Home/hzhb.vue"
import Footer from "../components/footer.vue"
import Menu from "../components/Menu.vue"

export default {
  data() {
    return {
      activeIndex: "1",
      img1: require("../assets/节点1.jpg"),
      img2: require("../assets/节点2.jpg"),
      img3: require("../assets/节点3.jpg"),
      img4: require("../assets/img/Home/phone/服务中心.jpg"),
      img5: require("../assets/img/Home/phone/电商平台.jpg"),
      img6: require("../assets/img/Home/phone/3大福利.jpg"),
      isPhone: false
    }
  },
  components: {
    Menu,
    Rotation,
    Terrace,
    Scheme,
    Rights,
    HZHB,
    Footer
  },
  methods: {
    get() {
      this.$nextTick(() => {
        const screenWidth = window.innerWidth
        if (screenWidth < 768) {
          this.isPhone = true
        }
      })
    }
  },
  created() {
    this.get()
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#Home {
  margin: 0;
  padding: 0;
  width: 100%;
}

.homeimg {
  width: 100%;
}

.excessive {
  text-align: center;
  font-size: 28px;
  padding: 5px 0;
  font-family: serif;
  background-color: rgba(3, 113, 240, 0.8);
  color: rgb(255, 255, 255);
}

#homeMenu {
  padding-left: 5%;
  margin: 1% 0;
}

a {
  text-decoration: none;
  color: black;
}
</style>

<template>
  <div>
    <div style="display: flex; align-items: center;background-color: whitesmoke;">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/logo.png">
        </router-link>
      </div>
      <el-menu :default-active="activeIndex" id="homeMenu" class="el-menu" mode="horizontal" :router=true
               v-show="isShowNav">
        <el-menu-item index="/">
          <span style="font-size: 16px;">首页</span>
        </el-menu-item>
        <el-menu-item index="/study">
          <span style="font-size: 16px;">卖家学习中心</span>
        </el-menu-item>
        <el-menu-item index="/navigation">
          <span style="font-size: 16px;">跨界导航</span>
        </el-menu-item>
        <el-submenu index="">
          <template slot="title"><span style="font-size: 16px;">图文翻译</span></template>
          <el-menu-item index="/fy">文字翻译</el-menu-item>
          <el-menu-item index="">图片翻译</el-menu-item>
          <el-menu-item index="">关键词词查询</el-menu-item>
          <el-menu-item index="/gpt">产品描述生成</el-menu-item>
          <el-menu-item index="/video">ozon进阶课程</el-menu-item>
          <el-menu-item index="/viparticle/">ozon进阶文章</el-menu-item>
          <el-menu-item index="/forum">用户交流中心</el-menu-item>
          <el-menu-item index="/sellerstats">ozon卖家数据</el-menu-item>
          <el-menu-item index="">ozon热搜词</el-menu-item>
          <el-menu-item index="">ozon产品数据</el-menu-item>
          <el-menu-item index="">热门商品</el-menu-item>
          <el-menu-item index="">运费计算</el-menu-item>
          <el-menu-item index="/profit">利润计算</el-menu-item>
          <el-menu-item index="">图片转网址</el-menu-item>
        </el-submenu>
        <el-menu-item index="/aboutc">
          <template slot="title"><span style="font-size: 16px;">关于我们</span></template>
        </el-menu-item>
      </el-menu>
      <el-button type="primary" icon="el-icon-menu" v-if="isShowNavBtn" class="nav-btn"
                 @click="toggleNav"></el-button>
      <div class="loginbtn" v-show="ishow">
        <router-link to="/login">
          <el-avatar>login</el-avatar>
        </router-link>
      </div>
      <div class="loginbtn" v-show="!ishow">
        <router-link to="/center">
          <el-avatar>user</el-avatar>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      ishow: true,
      isShowNav: true,
      isShowNavBtn: false,
    };
  },
  methods: {
    start() {
      if (this.$store.state.userRole !== "游客") {
        this.ishow = !this.ishow;
      }
    },
    toggleNav() {
      this.isShowNav = !this.isShowNav;
    },
    handleResize() {
      this.isShowNavBtn = window.innerWidth <= 860;
      this.isShowNav = window.innerWidth > 860;
    },
  },
  created() {
    this.start();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.nav-btn {
  border: 0;
  margin: 10px 1% 1% 45%;
}

.logo img {
  width: 120px;
  height: 60px;
  margin: 10px 0 0 10px;
}

.loginbtn {
  margin: 10px 10px 0 auto;
}

@media screen and (max-width: 860px) {
  #homeMenu {
    position: fixed;
    z-index: 999;
    top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }

  .logo img {
    width: 80px;
    height: 40px;
  }

  el-menu-item {
    flex: 0 0 45%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  el-menu-item {
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
}

#homeMenu {
  background-color: whitesmoke;
  margin: 0 10% 0 200px;
  padding: 12px 0;
}

.loginbutton {
  text-align: center;
  background-color: rgb(0, 140, 255);
  color: aliceblue;
  font-size: 18px;
  width: 120px;
  height: 48px;
  border-color: rgba(0, 119, 255, 0.788);
  box-shadow: 0 5px 10px rgba(0, 119, 255, 0.788);
}

a {
  text-decoration: none;
  color: black;
}
</style>

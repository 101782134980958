<template>
  <div class="partner">
    <h1 class="partner-title">合作伙伴</h1>
    <div class="hzhbdiv">
      <div v-for="(item, index) in data" :key="index">
        <a :href="item.cooperativeUrl" target="_blank">
          <img :src="item.cooperativeImg" class="img3">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: []
    }
  },
  methods: {
    get() {
      this.$axios.get("/cooperative/getlikename")
        .then((res) => {
            for (let i = 0; i < 24; i++) {
              this.data.push(res.data.data[i])
            }
            this.data.forEach(element => {
              element.cooperativeImg = require('../../assets/img/cooperative/' + element.cooperativeImg)
            });
          }
        )
    }
  },
  created() {
    this.get()
  }
}
</script>

<style scoped>
.partner {
  padding: 50px 0 50px 3%;
  text-align: center;
}

.partner-title {
  font-size: 38px;
  margin-bottom: 50px;
}

h1 {
  margin: 0 5% 20px 0;
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.hzhbdiv {
  flex-wrap: wrap;
  display: flex;
  text-align: center;
  margin: 0 5%;
  padding-left: 2%;
}

.hzhbdiv a {
  margin: 10px;
  text-decoration: none;
}

.hzhbdiv .img3 {
  margin: 10px auto;
  width: 230px;
  height: 90px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.hzhbdiv .img3:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  display: none;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


@media (max-width: 768px) {

  .partner {
    padding: 0;
    text-align: center;
  }

  .hzhbdiv {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .hzhbdiv > div {
    white-space: nowrap;
    display: inline-block;
  }

}
</style>

<template>
  <div id="terrace">
    <div class="ullist" style="text-align: center;">
      <div class="img1">
        <img src="../../assets/数据1.jpg" alt=""/>
      </div>
      <div class="img2">
        <img src="../../assets/数据2.jpg" alt=""/>
      </div>
      <div class="img3">
        <img src="../../assets/数据3.jpg" alt=""/>
      </div>
      <div class="img4">
        <img src="../../assets/数据4.jpg" alt=""/>
      </div>
      <div class="img5">
        <img src="../../assets/数据5.jpg" alt=""/>
      </div>
    </div>
    <div class="ullistP" style="text-align: center;">
      <div class="img1">
        <img src="../../assets/数据1.1.jpg" alt=""/>
      </div>
      <div class="img2">
        <img src="../../assets/数据2.2.jpg" alt=""/>
      </div>
      <div class="img3">
        <img src="../../assets/数据3.3.jpg" alt=""/>
      </div>
      <div class="img4">
        <img src="../../assets/数据4.4.jpg" alt=""/>
      </div>
      <div class="img5">
        <img src="../../assets/数据5.5.jpg" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
#terrace {
  margin-top: 20px;
  width: 100%;
}

.ullist {
  width: 100%;
  display: flex;
}

.ullist img {
  width: 80%;
  height: 100%;
}

.ullist .img1:hover img {
  content: url(../../assets/数据1.1.jpg);
  width: 100%;
  height: 100%;
}

.ullist .img2:hover img {
  content: url(../../assets/数据2.2.jpg);
  width: 100%;
  height: 100%;
}

.ullist .img3:hover img {
  content: url(../../assets/数据3.3.jpg);
  width: 100%;
  height: 100%;
}

.ullist .img4:hover img {
  content: url(../../assets/数据4.4.jpg);
  width: 100%;
  height: 100%;
}

.ullist .img5:hover img {
  content: url(../../assets/数据5.5.jpg);
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@media (min-width: 768px) {

  .ullist {
    display: flex;
  }

  .ullistP {
    margin: 10px 0;
    display: none;
  }
}

@media (max-width: 767px) {

  .ullist {
    display: none;
  }

  .ullistP {
    padding: 5px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .ullistP img {
    width: 342px;
    margin: 0 10px;
    height: auto;
    box-shadow: 0px 0px 3px 3px rgba(202, 202, 202, 0.219);
  }

}
</style>

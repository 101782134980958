<template>
  <div>
    <Menu></Menu>
    <div>
      <div id="fy">
        <h2 style="color: #555;margin-bottom: 30px;">俄语在线翻译(服务暂停)</h2>
        <div style="display: flex;">
          <div style="width: 40%;margin-left:10%;margin-right: 10px;">
            <el-input v-model="prompt" placeholder="请输入需要翻译的语句" type="textarea"
                      :autosize="{ minRows: 5, maxRows: 100 }"></el-input>
          </div>
          <el-input type="textarea" v-model="anser" style="width: 40%;margin-right:10%;margin-left: 10px;"
                    :autosize="{ minRows: 5, maxRows: 100 }" placeholder="输出翻译后的语句"></el-input>
        </div>
        <el-button @click="gpt()" @submit.native.prevent style="margin: 30px 0 20px 0;">翻译</el-button>
      </div>
      <Footer style="margin-top: 100pxF;"></Footer>
    </div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue"
import Footer from "../components/footer.vue"

export default {
  data() {
    return {
      messages: [{"role": "system", "content": "将我以下的语句翻译成俄语"}],
      prompt: "",
      anser: ""
    }
  },
  methods: {
    // gpt() {
    //     this.messages.push({ "role": "user", "content": this.prompt })
    //     this.$axios({
    //         method: 'post',
    //         url: 'https://api.openai.com/v1/chat/completions',
    //         data: {
    //             model: 'gpt-3.5-turbo',
    //             messages: this.messages,
    //             temperature: 0.9,
    //             max_tokens: 2000,
    //             top_p: 1,
    //             frequency_penalty: 0.0,
    //             presence_penalty: 0.6
    //         },
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer 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",
    //         }
    //     })
    //         .then((res) => {
    //             this.anser = res.data.data['choices'][0]['message']['content'];
    //             this.messages.push({ "role": "assistant", "content": this.anser })
    //             // console.log(res.data.data.choices);
    //         })
    //     // .catch((err) => {
    //     //     console.log(err.response);
    //     //     return "error";
    //     // });
    // }
  },
  components: {
    Menu,
    Footer
  }
}

</script>

<style scoped>
#fy {
  text-align: center;
  margin: 50px 20%;
  padding: 20px 0;
  background-color: rgba(250, 235, 215, 0.507);
  border-radius: 5px;
}

.el-button {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  color: #7f7f7f;
  border: none;
  border-radius: 5px;
  padding: 12px 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.el-button:hover {
  background-color: #7f7f7f;
  color: white;
}
</style>

<template>
  <div id="back">
    <div id="head"
         style="text-align: center;background-color: rgba(122, 122, 122, 0.1000);margin: 0;padding: 5px;height: 90px;">
      <router-link to="/">
        <el-image style="width: 120px; height: 60px;float: left;margin-left: 20px;margin-top: 10px;"
                  src="https://i.tosoiot.com/20230414/t/9bcb21b88bdfb3dc.png">
        </el-image>
      </router-link>
      <h1 style="margin-top: 20px;margin-left: -20px;">白桦林后台管理系统</h1>
    </div>
    <el-row class="tac">
      <el-col :span="12">
        <el-menu default-active="2" class="el-menu-vertical" @open="handleOpen" @close="handleClose" :router=true
                 :mode="menuMdoe" :collapse="isCollapse">
          <el-submenu index="1" style="margin-top: 20px;padding-left: 10px;">
            <template slot="title">
              <i>文章</i>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/back/article">文章查询</el-menu-item>
              <el-menu-item index="/back/addarticle">文章添加</el-menu-item>
              <!-- <el-menu-item index="/back/updatearticle">文章修改</el-menu-item> -->
              <el-menu-item index="/back/delarticle">文章删除</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2" style="padding-left: 10px;">
            <template slot="title">
              <i>用户</i>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/back/user">用户查询</el-menu-item>
              <el-menu-item index="/back/adduser">用户添加</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" style="padding-left: 10px;">
            <template slot="title">
              <i>合作</i>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/back/cooperative">合作查询</el-menu-item>
              <el-menu-item index="/back/addcooperative">合作添加</el-menu-item>
              <!-- <el-menu-item index="/back/updatecooperative">合作修改</el-menu-item> -->
              <el-menu-item index="/back/delcooperative">合作删除</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4" style="padding-left: 10px;">
            <template slot="title">
              <i>评论</i>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/back/comment">评论查询</el-menu-item>
              <el-menu-item index="/back/addcomment">评论添加</el-menu-item>
              <!-- <el-menu-item index="/back/updatecomment">评论修改</el-menu-item> -->
              <el-menu-item index="/back/delcomment">评论删除</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
    <el-main id="backMain">
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>

export default {
  data() {
    return {
      menuMdoe: 'vertical',
      isCollapse: false,
    }
  },
  methods: {
    mobile() {
      if (window.innerWidth <= 760) {
        this.menuMdoe = "horizontal"
        this.isCollapse = true
      }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
  },
  components: {},
  created() {
    this.mobile()
  }
}
</script>

<style>
@media screen and (max-width: 860px) {

  .el-menu--horizontal ul {
    width: auto;
    margin-left: 60px;
  }

}
</style>

<style scoped>
.tac {
  float: left;
}

.el-menu-vertical {
  width: 250px;
  float: left;
  margin-left: 3%;
}

@media screen and (max-width: 860px) {

  .tac {
    width: 70px;
    text-align: center;
  }

  .tac ul {
    width: 70px;
  }

  .tac li {
    padding: 0 !important;
  }

}
</style>

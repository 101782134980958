<template>
  <div id="study">
    <Menu></Menu>
    <img :src="imgUrl" class="zszximg">
    <el-main class="studymain">
      <router-view/>
    </el-main>
    <Footer></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue"
import Footer from "../components/footer.vue"

export default {
  data() {
    return {
      imgUrl: require('../assets/img/study/平台运营服务（23.4.3）.jpg')
    }
  },
  methods: {
    get() {
      this.$nextTick(() => {
        const screenWidth = window.innerWidth
        if (screenWidth < 768) {
          this.imgUrl = require('../assets/img/study/phone/知识中心.jpg')
        }
      })
    },
  },
  components: {
    Menu,
    Footer
  },
  created() {
    // this.get()
  }
}
</script>

<style scoped>
.studymain {
  margin: 0 8%;
}

.zszximg {
  height: auto;
  width: 100%;
}

@media (max-width: 768px) {
  .studymain {
    margin: 0;
    padding: 0;
  }
}
</style>
